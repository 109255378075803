export const About = (props) => {
  return (
    <div id="about">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="img/Half Thor_001.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>About Us</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
            <a href="https://solana-storm.gitbook.io/storm-labs/introduction/welcome-to-storm-labs" target="_blank">Storm Labs WhitePaper</a>
          </div>
        </div>
      </div>
    </div>
  );
};
